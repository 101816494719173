<template>
  <div>
    <b-modal
      size="xl"
      id="add_fuel_modal"
      title="Ingrese nuevo combustible"
      @ok="addPump"
    >
      <div class="row">
        <div class="col-md-6">
          <b-form-group label="Identificador">
            <b-form-input
              v-model="formPump.name"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Combustible">
            <multiselect
              v-model="formPump.item"
              track-by="id"
              label="canonical_name"
              placeholder="Selecciona combustible"
              :options="fuels"
              :searchable="true"
              :allow-empty="false"
              @search-change="searchFuel"
            >
            </multiselect>
          </b-form-group>
        </div>
      </div>
    </b-modal>
    <div class="row">
      <div class="col-md-12">
        <b-card>
          <b-card-text>
            <div>
              <b-form>
                <div class="row">
                  <b-form-group label="Ubicación" class="col-md-6">
                    <multiselect
                      v-model="form.warehouse"
                      track-by="id"
                      label="name"
                      placeholder="Selecciona ubicación"
                      :options="warehouses"
                      :searchable="true"
                      :allow-empty="false"
                    >
                    </multiselect>
                  </b-form-group>
                  <b-form-group label="Nombre" class="col-md-6">
                    <b-form-input type="text" v-model="form.name">
                    </b-form-input>
                  </b-form-group>
                </div>
              </b-form>
            </div>
            <div class="row" v-if="isEdit === true">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table table-borderless table-vertical-center">
                    <thead>
                      <tr>
                        <th class="p-0">Nombre</th>
                        <th class="p-0">Combustible</th>
                        <th class="p-0">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in form.pumps" :key="item.id">
                        <td class="pl-0 py-4">
                          {{ item.name }}
                        </td>
                        <td class="pl-0">
                          {{ item.fuel.name }}
                        </td>
                        <td class="text-right pr-0">
                          <b-button
                            variant="danger"
                            size="sm"
                            @click="removePump(item)"
                          >
                            <b-icon icon="trash"></b-icon>
                          </b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <b-button variant="primary" class="mr-2" @click="save()">
                  Guardar
                </b-button>
                <b-button
                  variant="warning"
                  class="mr-2"
                  @click="openModalAddFuel()"
                >
                  Añadir tipo combustible
                </b-button>
              </div>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Utils from "@/core/utils/utils";

export default {
  name: "fuelDispenserCreateEdit",
  data() {
    return {
      id: null,
      form: {
        warehouse: null,
        name: null,
      },
      formPump: {
        name: null,
        item: null,
        dispenser_id: null,
      },
      fuels: [],
      method: ApiService.post,
      url: "fuel/dispenser/",
      warehouses: [],
    };
  },
  methods: {
    addPump() {
      if (this.formPump.item === null) {
        this.$errorToast("Debe seleccionar un combustible");
        return;
      }
      const pump = {
        fuel: this.formPump.item.id,
        dispenser: this.formPump.dispenser_id,
        name: this.formPump.name,
      };
      ApiService.post("fuel/pump/", pump)
        .then((res) => {
          this.cleanPumpModal(res);
        })
        .catch(() => {
          this.$errorToast("Hubo un error al guardar este surtidor");
        });
    },
    cleanPumpModal(res) {
      this.form.pumps.push(res.data);
      this.formPump.name = null;
      this.formPump.item = null;
      this.$successToast("Lado-Combustible guardado");
      this.$bvModal.hide("add_fuel_modal");
    },
    getItem() {
      if (this.$route.params.id === undefined) {
        return false;
      }
      this.isEdit = true;
      this.method = ApiService.patch;
      ApiService.get(`fuel/dispenser/${this.$route.params.id}`).then((res) => {
        this.form = res.data;
        this.form.warehouse = this.warehouses.find(
          (w) => w.id === this.form.warehouse
        );
        this.url = `fuel/dispenser/${this.$route.params.id}/`;
        this.form.id = this.$route.params.id;
      });
      this.formPump.dispenser_id = this.$route.params.id;
      this.$store.dispatch(SET_BREADCRUMB, [{ title: `Editar surtidor` }]);
    },
    removePump(item) {
      this.form.pumps = this.form.pumps.filter((p) => p.id !== item.id);
      ApiService.delete(`fuel/pump/${item.id}/`).catch(() => {
        this.$errorToast("Hubo un error al eliminar este surtidor");
        this.form.pumps.push(item);
      });
    },
    save() {
      if (this.validateSave() === false) {
        return;
      }

      let form = { ...this.form };
      form.pos_key = localStorage.getItem("pos_key");
      form.warehouse = Utils.isNotNullOrEmpty(this.form.warehouse)
        ? this.form.warehouse.id
        : null;
      this.method(this.url, form)
        .then(() => {
          this.$successToast("Surtidor guardada");
          this.$router.push({ name: "fuel_dispenser_list" });
        })
        .catch(() => {
          this.$errorToast("Hubo un error al guardar este envío");
        });
    },
    searchFuel(query) {
      if (typeof window.LIT !== "undefined") {
        clearTimeout(window.LIT);
      }
      window.LIT = setTimeout(() => {
        ApiService.get(
          "core/item",
          `?page=1&page_size=10&canonical_name=${query}`
        ).then((res) => {
          this.fuels = res.data.results;
        });
      }, 750);
    },
    getWarehouses() {
      ApiService.get(`core/warehouse`, "?page=1&page_size=100000").then(
        (res) => {
          this.warehouses = res.data.results;
        }
      );
    },
    openModalAddFuel() {
      this.$bvModal.show("add_fuel_modal");
    },
    validateSave() {
      if (this.form.warehouse === null) {
        this.$errorToast("Debe seleccionar al menos una ubicación");
        return false;
      }
      if (!Utils.isNotNullOrEmpty(this.form.name)) {
        this.$errorToast("Ingresa un nombre para el surtidor");
        return false;
      }
      return true;
    },
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: `Crear surtidor` }]);
    await this.getWarehouses();
    await this.getItem();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.btn-right {
  margin-left: auto;
}

.vue-portal-target {
  width: 100% !important;
}

.label__create {
  font-weight: bold;
  float: right;
  margin-top: -25px;
  color: #3699ff;
  cursor: pointer;
}
</style>
